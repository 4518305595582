.work-container{
  width: 70%;
  min-width: 300px;
  display: flex;
  justify-content: space-around;
}

.hidden{
  opacity: 0;
  visibility: hidden;
}

.show {
  opacity: 1;
  transition: opacity 1500ms ease-in-out;
  transition-delay: 3000ms;
  visibility: visible;
}  

.work-item{
  text-align: start;
  padding: 10px 0px;
  color: white;
  display: flex;
  align-items: center;
}

.work-title{
  color: white;
  font-size: 16px;
}

.work-preview{
  align-items: center;
  display: flex;
  justify-content: center;
}

.preview-desc{
  font-size: 14px;
}

@media (max-width: 600px) {
  .work-title{
    color: white;
    font-size: 12px;
  } 
  .work-preview{
    width: 0px;
    visibility: hidden;
  }
}